<script setup lang="ts">
import { useCookieConsentStore } from '~/stores/useCookieConsentStore'

const ccStore = useCookieConsentStore()
</script>

<template>
  <div v-if="ccStore.isTargetingAccepted" class="flex justify-center">
    <GoogleAd ad-unit-id="div-gpt-ad-1732635168282-0" ad-unit-path="/21869305879/Primis_VDU" :width="1" :height="1" />
  </div>
</template>

<style scoped></style>
